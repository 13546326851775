<template>
  <div>
    <b-nav-item-dropdown
      class="dropdown-notification dropdown-no-scroll mr-25"
      menu-class="dropdown-menu-media"
      right
    >
      <template #button-content>
        <feather-icon
          :key="bellKey"
          :badge="( statusNotificationCount + financeNotificationCount + otherNotificationCount + PickupRequestNotificationCount + RedeliveryNotificationsCount ) > 0 ? (statusNotificationCount + financeNotificationCount + otherNotificationCount + PickupRequestNotificationCount + RedeliveryNotificationsCount) : ''"
          badge-classes="bg-danger"
          class="text-body"
          icon="BellIcon"
          size="21"
        />
      </template>

      <!-- Header -->
      <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
          <h4 class="notification-title mb-0 mr-auto">
            Notifications
          </h4>
          <b-badge
            pill
            variant="light-primary"
          >
            {{ statusNotificationCount + financeNotificationCount + otherNotificationCount + PickupRequestNotificationCount + RedeliveryNotificationsCount }} New
          </b-badge>
        </div>
      </li>
      <b-tabs
        content-class="mt-2"
        justified
      >
        <b-tab class="mb-1">
          <template #title>
            <feather-icon
              icon="BoxIcon"
              class="m-1"
              size="20"
            />
          </template>
          <b-card-text>
            <!-- Header -->
            <li class="dropdown-menu-header">
              <div class="dropdown-header d-flex">
                <h4 class="notification-title mb-0 mr-auto">
                  Order Status Notifications
                </h4>
                <b-badge
                  pill
                  variant="light-primary"
                >
                  {{ statusNotificationCount }} New
                </b-badge>
              </div>
            </li>
            <!-- Notifications -->
            <vue-perfect-scrollbar
              :key="dropdownKey"
              :settings="perfectScrollbarSettings"
              class="scrollable-container media-list scroll-area"
              tagname="li"
            >
              <div v-if="statusNotifications.length > 0">
                <b-link
                  v-for="notification in statusNotifications"
                  :key="notification.id"
                  @click="onClickViewNotification(notification)"
                >
                  <b-media>
                    <template #aside>
                      <b-avatar
                        size="32"
                        variant="light-warning"
                      >
                        <feather-icon icon="BoxIcon" />
                      </b-avatar>
                    </template>
                    <p class="media-heading">
                      <span class="font-weight-bolder">
                        {{ notification.message }}
                      </span>
                    </p>
                    <small class="notification-text">{{ __dateTimeFormatterPassedTime(notification.created_at) }}</small>
                  </b-media>
                </b-link>
              </div>

              <div
                v-else
                class="media d-flex align-items-center"
              >
                <p class="media-heading">
                  <span class="font-weight-bolder">
                    You have no unread  order Status notifications
                  </span>
                </p>
              </div>
            </vue-perfect-scrollbar>
            <!-- Cart Footer -->
            <li class="dropdown-menu-footer">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                block
                :to="{ name: 'notifications' }"
                @click="onReadAllClick()"
              >
                Read all notifications
              </b-button>
            </li>
          </b-card-text>
        </b-tab>
        <b-tab class="mb-1">
          <template #title>
            <feather-icon
              icon="DollarSignIcon"
              size="20"
              class="m-1"
            />
          </template>
          <b-card-text>
            <!-- Header -->
            <li class="dropdown-menu-header">
              <div class="dropdown-header d-flex">
                <h4 class="notification-title mb-0 mr-auto">
                  Finance Notifications
                </h4>
                <b-badge
                  pill
                  variant="light-primary"
                >
                  {{ financeNotificationCount }} New
                </b-badge>
              </div>
            </li>
            <!-- Notifications -->
            <vue-perfect-scrollbar
              :key="dropdownKey"
              :settings="perfectScrollbarSettings"
              class="scrollable-container media-list scroll-area"
              tagname="li"
            >
              <div v-if="financeNotifications.length > 0">
                <b-link
                  v-for="notification in financeNotifications"
                  :key="notification.id"
                  @click="onClickViewNotification(notification)"
                >
                  <b-media>
                    <template #aside>
                      <b-avatar
                        size="32"
                        variant="light-warning"
                      >
                        <feather-icon
                          icon="DollarSignIcon"
                          size="20"
                        />
                      </b-avatar>
                    </template>
                    <p class="media-heading">
                      <span class="font-weight-bolder">
                        {{ notification.message }}
                      </span>
                    </p>
                    <small class="notification-text">{{ __dateTimeFormatterPassedTime(notification.created_at) }}</small>
                  </b-media>
                </b-link>
              </div>

              <div
                v-else
                class="media d-flex align-items-center"
              >
                <p class="media-heading">
                  <span class="font-weight-bolder">
                    You have no unread finance notifications
                  </span>
                </p>
              </div>
            </vue-perfect-scrollbar>
            <!-- Cart Footer -->
            <li class="dropdown-menu-footer"><b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              :to="{ name: 'notifications' }"
              @click="onReadAllClick()"
            >Read all notifications</b-button>
            </li>
          </b-card-text>
        </b-tab>
        <b-tab active>
          <template #title>
            <feather-icon
              icon="TruckIcon"
              size="20"
              class="m-1"
            />
          </template>
          <b-card-text>
            <!-- Header -->
            <li class="dropdown-menu-header">
              <div class="dropdown-header d-flex">
                <h4 class="notification-title mb-0 mr-auto">
                  Pickup Requests
                </h4>
                <b-badge
                  pill
                  variant="light-primary"
                >
                  {{ PickupRequestNotificationCount }} New
                </b-badge>
              </div>
            </li>
            <!-- Notifications -->
            <vue-perfect-scrollbar
              :key="dropdownKey"
              :settings="perfectScrollbarSettings"
              class="scrollable-container media-list scroll-area"
              tagname="li"
            >
              <div v-if="PickupRequestNotifications.length > 0">
                <b-link
                  v-for="notification in PickupRequestNotifications"
                  :key="notification.id"
                  @click="onClickViewNotification(notification)"
                >
                  <b-media>
                    <template #aside>
                      <b-avatar
                        size="32"
                        variant="light-warning"
                      >
                        <feather-icon
                          icon="TruckIcon"
                          size="20"
                        />
                      </b-avatar>
                    </template>
                    <p class="media-heading">
                      <span class="font-weight-bolder">
                        {{ notification.message }}
                      </span>
                    </p>
                    <small class="notification-text">{{ __dateTimeFormatterPassedTime(notification.created_at) }}</small>
                  </b-media>
                </b-link>
              </div>

              <div
                v-else
                class="media d-flex align-items-center"
              >
                <p class="media-heading">
                  <span class="font-weight-bolder">
                    You have no unread  pickup requests notifications
                  </span>
                </p>
              </div>
            </vue-perfect-scrollbar>
            <!-- Cart Footer -->
            <li class="dropdown-menu-footer"><b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              :to="{ name: 'notifications' }"
              @click="onReadAllClick()"
            >Read all notifications
            </b-button>
            </li>
          </b-card-text>
        </b-tab>
        <b-tab active>
          <template #title>
            <feather-icon
              icon="RotateCwIcon"
              size="20"
              class="m-1"
            />
          </template>
          <b-card-text>
            <!-- Header -->
            <li class="dropdown-menu-header">
              <div class="dropdown-header d-flex">
                <h4 class="notification-title mb-0 mr-auto">
                  Redelivery Notifications
                </h4>
                <b-badge
                  pill
                  variant="light-primary"
                >
                  {{ RedeliveryNotificationsCount }}New
                </b-badge>
              </div>
            </li>
            <!-- Notifications -->
            <vue-perfect-scrollbar
              :key="dropdownKey"
              :settings="perfectScrollbarSettings"
              class="scrollable-container media-list scroll-area"
              tagname="li"
            >
              <div v-if="RedeliveryNotifications.length > 0">
                <b-link
                  v-for="notification in RedeliveryNotifications"
                  :key="notification.id"
                  @click="onClickViewNotification(notification)"
                >
                  <b-media>
                    <template #aside>
                      <b-avatar
                        size="32"
                        variant="light-warning"
                      >
                        <feather-icon
                          size="20"
                          icon="RotateCwIcon"
                        />
                      </b-avatar>
                    </template>
                    <p class="media-heading">
                      <span class="font-weight-bolder">
                        {{ notification.waybill_number }} - {{ notification.merchant_name }}
                      </span>
                    </p>
                    <small class="notification-text">{{ __dateTimeFormatterPassedTime(notification.created_at) }}</small>
                  </b-media>
                </b-link>
              </div>

              <div
                v-else
                class="dropdown-header d-flex align-items-center"
              >
                <p class="media-heading">
                  <span class="font-weight-bolder">
                    You have no unread redelivery notifications
                  </span>
                </p>
              </div>
            </vue-perfect-scrollbar>
            <!-- Cart Footer -->
            <li class="dropdown-menu-footer"><b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              :to="{ name: 'notifications' }"
              @click="onReadAllClick()"
            >Read all notifications</b-button>
            </li>
          </b-card-text>
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="25"
              class="m-1"
            />
          </template>
          <b-card-text>
            <!-- Header -->
            <li class="dropdown-menu-header">
              <div class="dropdown-header d-flex">
                <h4 class="notification-title mb-0 mr-auto">
                  Other Notifications
                </h4>
                <b-badge
                  pill
                  variant="light-primary"
                >
                  {{ otherNotificationCount }} New
                </b-badge>
              </div>
            </li>
            <!-- Notifications -->
            <vue-perfect-scrollbar
              :key="dropdownKey"
              :settings="perfectScrollbarSettings"
              class="scrollable-container media-list scroll-area"
              tagname="li"
            >
              <div v-if="otherNotifications.length > 0">
                <b-link
                  v-for="notification in otherNotifications"
                  :key="notification.id"
                  @click="onClickViewNotification(notification)"
                >
                  <b-media>
                    <template #aside>
                      <b-avatar
                        size="32"
                        variant="light-warning"
                      >
                        <feather-icon
                          size="20"
                          icon="UserIcon"
                        />
                      </b-avatar>
                    </template>
                    <p class="media-heading">
                      <span class="font-weight-bolder">
                        {{ notification.message }}
                      </span>
                    </p>
                    <small class="notification-text">{{ __dateTimeFormatterPassedTime(notification.created_at) }}</small>
                  </b-media>
                </b-link>
              </div>

              <div
                v-else
                class="media d-flex align-items-center"
              >
                <p class="media-heading">
                  <span class="font-weight-bolder">
                    You have no unread other notifications
                  </span>
                </p>
              </div>
            </vue-perfect-scrollbar>
            <!-- Cart Footer -->
            <li class="dropdown-menu-footer"><b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              :to="{ name: 'notifications' }"
              @click="onReadAllClick()"
            >Read all notifications</b-button>
            </li>
          </b-card-text>
        </b-tab>
      </b-tabs>
    <!-- Notifications -->
    </b-nav-item-dropdown>
    <view-notification-modal ref="view_notification_modal" />
  </div>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BTabs, BTab, BCardText,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import bellSound from '@/assets/notification/notification-bell.mp3'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/extensions
import ViewNotificationModal from '@/components/Notification/ViewNotificationModal'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const notificationRepository = RepositoryFactory.get('notification')

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    ViewNotificationModal,
    BTabs,
    BTab,
    BCardText,
  },
  directives: {
    Ripple,
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      statusNotifications: [],
      financeNotifications: [],
      otherNotifications: [],
      PickupRequestNotifications: [],
      RedeliveryNotifications: [],
      perfectScrollbarSettings,
      bellKey: 1,
      dropdownKey: 1,
      audio: null,
      otherNotificationCount: 0,
      statusNotificationCount: 0,
      financeNotificationCount: 0,
      PickupRequestNotificationCount: 0,
      RedeliveryNotificationsCount: 0,
    }
  },
  mounted() {
    this.initNotificationLoop()
    this.audio = new Audio(bellSound)
  },
  methods: {
    async getReminderNotifications() {
      const { data, meta } = (await notificationRepository.getReminderNotifications()).data
      this.statusNotificationCount = meta.total
      this.statusNotifications = data
      this.bellKey += 1
      this.dropdownKey += 1
    },
    async getFinanceNotifications() {
      const { data, meta } = (await notificationRepository.getFinanceNotifications()).data
      this.financeNotificationCount = meta.total
      this.financeNotifications = data
      this.bellKey += 1
      this.dropdownKey += 1
    },
    async getOtherNotifications() {
      const { data, meta } = (await notificationRepository.getOtherNotifications()).data
      this.otherNotificationCount = meta.total
      this.otherNotifications = data
      this.bellKey += 1
      this.dropdownKey += 1
    },
    async getPickupRequestNotifications() {
      const { data, meta } = (await notificationRepository.getPickupRequestNotifications('1', 'filter[read_at]=null')).data
      this.PickupRequestNotificationCount = meta.total
      this.PickupRequestNotifications = data
      this.bellKey += 1
      this.dropdownKey += 1
    },
    async getRedeliveryNotifications() {
      const { data, meta } = (await notificationRepository.getRedeliveryNotifications()).data
      this.RedeliveryNotificationsCount = meta.total
      this.RedeliveryNotifications = data
      this.bellKey += 1
      this.dropdownKey += 1
    },
    async initNotificationLoop() {
      await this.getPickupRequestNotifications()
      await this.getReminderNotifications()
      await this.getFinanceNotifications()
      await this.getOtherNotifications()
      await this.getRedeliveryNotifications()
      if ((this.statusNotificationCount + this.financeNotificationCount + this.otherNotificationCount + this.PickupRequestNotificationCount) > 0) {
        this.audio.play()
      }
      setTimeout(() => {
        this.initNotificationLoop()
      }, 300000)
    },
    onReadAllClick() {
      document.body.click()
    },
    onClickViewNotification(notification) {
      this.$refs.view_notification_modal.openModal(notification, 'View Notification')
    },
  },
}
</script>
<style scoped>
.text-body {
    color: #e2baba !important;
}
.nav-link svg {
    margin-right: 0.5rem;
    color: black;
}
</style>
