<template>
  <ul>
    <!--    eslint-disable-->
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in filteredItems"
      v-if="checkPermission(item.permission)"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import { mapGetters } from 'vuex'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

const FinanceSettingsRepository = RepositoryFactory.get('financeSetting')

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      form: {
        commission_invoice: {
          rider_commission_invoice: {
            enabled: false,
          },
          agency_commission_invoice: {
            enabled: false,
          },
        },
      },
    }
  },
  setup() {
    provide('openGroups', ref([]))
    return {
      resolveNavItemComponent,
    }
  },
  computed: {
    ...mapGetters(['permissions']),
    filteredItems() {
      return this.items.map(item => {
        if (item.title === 'Commissions') {
          // Only show the Commissions section if rider_commission_invoice or agency_commission_invoice is enabled
          if (this.form.commission_invoice.rider_commission_invoice.enabled || this.form.commission_invoice.agency_commission_invoice.enabled) {
            const filteredChildren = item.children.filter(child => {
              if (child.title === 'Rider') {
                return this.form.commission_invoice.rider_commission_invoice.enabled
              }
              if (child.title === 'Agent') {
                return this.form.commission_invoice.agency_commission_invoice.enabled
              }
              return true
            })
            return { ...item, children: filteredChildren }
          }
          return null
        }
        return item
      }).filter(Boolean)
    },
  },
  mounted() {
    this.readFinanceSetting()
  },
  methods: {
    // eslint-disable-next-line consistent-return,no-unused-vars
    checkPermission(routePermissions) {
      if (this.permissions && routePermissions) {
        return this.permissions.some(i => routePermissions.includes(i))
      }
      return true
    },
    async readFinanceSetting() {
      try {
        const { data } = (await FinanceSettingsRepository.getFinanceSettingsResource()).data
        this.form = data
        localStorage.setItem('finance_setting', JSON.stringify(data))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
  },
}
</script>
