export default [
  {
    header: 'Dashboards',
    permission: ['dashboard', 'warehouse-dashboard'],
  },
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
    permission: ['dashboard'],
  },
  {
    title: 'Warehouse',
    route: 'warehouse-select',
    icon: 'PieChartIcon',
    permission: ['warehouse-dashboard'],
  },
  {
    header: 'Operations',
    permission: ['main-operation', 'warehouse-operation', 'pickup-operation', 'view-orders'],
  },
  {
    title: 'All Orders',
    route: 'all-orders',
    icon: 'AlignJustifyIcon',
    permission: ['view-orders'],
  },
  {
    title: 'Order Create',
    route: 'order-create',
    icon: 'PlusCircleIcon',
    permission: ['create-merchant-orders'],
  },
  {
    title: 'Operation',
    icon: 'CodepenIcon',
    permission: ['main-operation', 'pickup-operation', 'warehouse-operation'],
    children: [
      {
        title: 'Main Office',
        route: 'main-operation',
        icon: 'PackageIcon',
        permission: ['main-operation'],
      },
      {
        title: 'Pickup Rider',
        route: 'pickup-operation',
        icon: 'PackageIcon',
        permission: ['pickup-operation'],
      },
      {
        title: 'Warehouse',
        route: 'warehouse-operation',
        icon: 'PackageIcon',
        permission: ['warehouse-operation'],
      },
    ],
  },
  {
    header: 'Finance',
    permission: ['rider-finance-view', 'branch-finance-view', 'agency-finance-view', 'merchant-finance-view'],
  },
  {
    title: 'Overview',
    icon: 'TrelloIcon',
    permission: ['rider-finance-view', 'branch-finance-view', 'agency-finance-view', 'merchant-finance-view'],
    children: [
      {
        title: 'Rider',
        route: 'rider-overview',
        icon: '',
        permission: ['rider-finance-view'],
      },
      {
        title: 'Agent',
        route: 'agent-overview',
        icon: '',
        permission: ['agency-finance-view'],
      },
      {
        title: 'Branch',
        route: 'branch-overview',
        icon: '',
        permission: ['branch-finance-view'],
      },
      {
        title: 'Merchant',
        route: 'merchant-overview',
        icon: '',
        permission: ['merchant-finance-view'],
      },
    ],
  },
  {
    title: 'Deposits',
    icon: 'DatabaseIcon',
    permission: ['rider-finance-view', 'branch-finance-view', 'agency-finance-view', 'merchant-finance-view'],
    children: [
      {
        title: 'Rider',
        route: 'rider-finance',
        icon: '',
        permission: ['rider-finance-view'],
      },
      {
        title: 'Agent',
        route: 'agent-finance',
        icon: '',
        permission: ['agency-finance-view'],
      },
      {
        title: 'Branch',
        route: 'branch-finance',
        icon: '',
        permission: ['branch-finance-view'],
      },
    ],
  },
  {
    title: 'Invoices',
    icon: 'LayersIcon',
    permission: ['merchant-finance-view'],
    children: [
      {
        title: 'Merchant',
        route: 'merchant-finance',
        icon: '',
        permission: ['merchant-finance-view'],
      },
      {
        title: 'Walking Customer',
        route: 'walking-finance',
        icon: '',
        permission: ['walking-finance-view'],
      },
    ],
  },
  {
    title: 'Commissions',
    icon: 'DivideCircleIcon',
    permission: ['rider-commission-finance-view', 'agency-commission-finance-view'],
    children: [
      {
        title: 'Rider',
        route: 'rider-commission',
        icon: '',
        permission: ['rider-commission-finance-view'],
      },
      {
        title: 'Agent',
        route: 'agency-commission',
        icon: '',
        permission: ['agency-commission-finance-view'],
      },
    ],
  },
  {
    header: 'Reports ',
    permission: ['sales-report', 'warehouse-status-count-report', 'merchant-status-count-report', 'end-customer-feedback-report', 'assigned-orders-to-delivery-rider-report', 'assigned-orders-to-pickup-rider-report', 'returned-orders-to-merchant-report', 'assigned-orders-to-warehouse-report', 'returned-orders-to-origin-warehouse-report', 'returned-orders-to-origin-warehouse-id-report', 'received-orders-to-origin-warehouse-report'],
  },
  {
    title: 'Reports',
    icon: 'FileTextIcon',
    permission: ['sales-report', 'warehouse-status-count-report', 'merchant-status-count-report', 'end-customer-feedback-report', 'assigned-orders-to-delivery-rider-report', 'assigned-orders-to-pickup-rider-report', 'returned-orders-to-merchant-report', 'assigned-orders-to-warehouse-report', 'returned-orders-to-origin-warehouse-report', 'returned-orders-to-origin-warehouse-id-report', 'received-orders-to-origin-warehouse-report'],
    children: [
      {
        title: 'Sales',
        route: 'monthly-sales',
        icon: '',
        permission: ['sales-report'],
      },
      {
        title: 'Status Count',
        icon: '',
        permission: ['warehouse-status-count-report', 'merchant-status-count-report'],
        children: [
          {
            title: 'Warehouse',
            route: 'warehouse-status-count',
            icon: '',
            permission: ['warehouse-status-count-report'],
          },
          {
            title: 'Merchant',
            route: 'merchant-status-count',
            icon: '',
            permission: ['merchant-status-count-report'],
          },
        ],
      },
      {
        title: 'Customer Feedback',
        route: 'all-feedbacks',
        icon: '',
        permission: ['end-customer-feedback-report'],
      },
      {
        title: 'Assigned Orders',
        icon: '',
        route: 'assigned-orders-reports',
        permission: ['assigned-orders-to-delivery-rider-report', 'assigned-orders-to-pickup-rider-report', 'returned-orders-to-merchant-report', 'returned-orders-to-origin-warehouse-report', 'assigned-orders-to-warehouse-report', 'returned-orders-to-origin-warehouse-invalid-destination-report', 'received-orders-to-origin-warehouse-report'],
      },
      {
        title: 'Finance',
        icon: '',
        permission: ['branch-expenses-report'],
        children: [
          {
            title: 'Branch Expenses ',
            route: 'branch-expenses-report',
            icon: '',
            permission: ['branch-expenses-report'],
          },
          {
            title: 'To Be Invoiced ',
            route: 'to-be-invoiced-report',
            icon: '',
            permission: ['to-be-invoiced-report'],
          },
          {
            title: 'Branch Progress ',
            route: 'branch-progress-report',
            icon: '',
            permission: ['branch-progress-report'],
          },
        ],
      },
    ],
  },
  {
    header: 'Communication',
    permission: ['view-chats'],
  },
  {
    title: 'Chat',
    route: 'chat',
    icon: 'MessageSquareIcon',
    permission: ['view-chats'],
  },
  {
    header: 'User Management',
    permission: ['view-merchant', 'view-staff', 'view-role'],
  },
  {
    title: 'Customers',
    icon: 'ShoppingBagIcon',
    permission: ['view-merchant', 'view-walking-customer'],
    children: [
      {
        title: 'Merchants',
        route: 'merchants',
        icon: '',
        permission: ['view-merchant'],
      },
      {
        title: 'Walking Customers',
        route: 'walking-customer',
        icon: '',
        permission: ['view-walking-customer'],
      },
    ],
  },
  {
    title: 'Staff',
    icon: 'UsersIcon',
    permission: ['view-staff', 'view-role'],
    children: [
      {
        title: 'Users',
        route: 'staff-users',
        icon: '',
        permission: ['view-staff'],
      },
      {
        title: 'Roles',
        route: 'roles',
        icon: '',
        permission: ['view-role'],
      },
    ],
  },
  {
    header: 'Support & Feedback',
    permission: ['view-tickets', 'view-feedback'],
  },
  {
    title: 'Tickets',
    route: 'tickets',
    icon: 'BookIcon',
    permission: ['view-tickets'],
  },
  {
    title: 'Feedback',
    route: 'feedback',
    icon: 'StarIcon',
    permission: ['view-feedback'],
  },
  {
    header: 'Administration',
    permission: ['city-view', 'zone-view', 'branch-view', 'agency-view', 'reason-view', 'quickbook-view', 'view-rider-rate-card', 'view-merchant-rate-card', 'view-agency-rate-card', 'view-walking-rate-card'],
  },
  {
    title: 'Waybill Request',
    icon: 'FileMinusIcon',
    route: 'waybillrequest',
    permission: ['index-handle-waybill-request'],
  },
  {
    title: 'Reasons',
    icon: 'BookIcon',
    route: 'reason',
    permission: ['reason-view'],
  },
  {
    title: 'QuickBooks',
    icon: 'BookOpenIcon',
    route: 'quickbook',
    permission: ['quickbook-view'],
  },
  {
    title: 'Demarcation',
    icon: 'GitMergeIcon',
    permission: ['city-view', 'state-view', 'zone-view', 'branch-view', 'agency-view'],
    children: [
      {
        title: 'City',
        route: 'city',
        icon: '',
        permission: ['city-view'],
      },
      {
        title: 'State',
        route: 'state',
        icon: '',
        permission: ['state-view'],
      },
      {
        title: 'Zone',
        route: 'zone',
        icon: '',
        permission: ['zone-view'],
      },
      {
        title: 'Branch',
        route: 'branch',
        icon: '',
        permission: ['branch-view'],
      },
      {
        title: 'Agent',
        route: 'agency',
        icon: '',
        permission: ['agency-view'],
      },
    ],
  },
  {
    title: 'Rate Cards',
    icon: 'DollarSignIcon',
    permission: ['view-rider-rate-card', 'view-merchant-rate-card', 'view-agency-rate-card', 'view-walking-rate-card'],
    children: [
      {
        title: 'Merchant',
        route: 'merchant-rate-card',
        permission: ['view-merchant-rate-card'],
        icon: '',
      },
      {
        title: 'Agent',
        route: 'agent-rate-card',
        icon: '',
        permission: ['view-agency-rate-card'],
      },
      {
        title: 'Rider',
        route: 'rider-rate-card',
        icon: '',
        permission: ['view-rider-rate-card'],
      },
      {
        title: 'Walking Customer',
        route: 'walking-customer-rate-card',
        icon: '',
        permission: ['view-walking-rate-card'],
      },
    ],
  },
  {
    title: 'Resources',
    icon: 'GlobeIcon',
    route: 'resources',
    permission: ['view-orders'],
  },
  {
    title: 'Approvals',
    icon: 'DollarSignIcon',
    permission: ['view-profile-update-approval', 'approve-profile-update-approval', 'show-profile-update-approval', 'reject-profile-update-approval'],
    children: [
      {
        title: 'Profile Update',
        route: 'profile-update-approval-request-list',
        icon: '',
        permission: ['view-profile-update-approval'],
      },
    ],
  },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    permission: ['business-profile-manage', 'edit-order-setting', 'edit-waybill-layout', 'edit-finance-setting', 'web-theme-setting', 'mobile-theme-setting', 'create-merchant-notification', 'edit-status-notification', 'edit-reminder-notification', 'edit-finance-notification', 'edit-other-notification', 'password-rest', 'view-status-settings', 'edit-status-settings', 'show-waybill-setting', 'edit-waybill-setting'],
    children: [
      {
        title: 'General',
        route: 'business-setting',
        icon: '',
        permission: ['business-profile-manage', 'edit-order-setting', 'edit-waybill-layout', 'edit-finance-setting', 'web-theme-setting', 'mobile-theme-setting', 'create-merchant-notification', 'edit-status-notification', 'edit-reminder-notification', 'edit-finance-notification', 'edit-other-notification', 'password-rest', 'show-waybill-setting', 'edit-waybill-setting'],
      },
      {
        title: 'Status',
        route: 'status-setting',
        icon: '',
        permission: ['view-status-settings'],
      },
    ],
  },
  {
    title: 'Subscription',
    icon: 'AwardIcon',
    permission: ['view-package', 'view-subscription', 'view-invoice'],
    children: [
      {
        title: 'Packages',
        route: 'package',
        icon: '',
        permission: ['view-package'],
      },
      {
        title: 'Subscription',
        route: 'subscription',
        icon: '',
        permission: ['view-subscription'],
      },
      {
        title: 'Billing',
        route: 'billing',
        icon: '',
        permission: ['view-invoice'],
      },
    ],
  },
]
