<template>
  <b-modal
    id="view-help-modal"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    :title="title"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <b-card>
        <div>
          <b-embed
            type="iframe"
            aspect="16by9"
            :src="link"
            allowfullscreen
          />
        </div>
      </b-card>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BModal,
  BOverlay,
  VBModal,
  BCard,
  BEmbed,
} from 'bootstrap-vue'

function initialState() {
  return {
    open: false,
    link: null,
    title: null,
    modalLoading: false,
  }
}

export default {
  directives: {
    'b-modal': VBModal,
  },
  components: {
    BCard,
    BModal,
    BOverlay,
    BEmbed,
  },
  data() {
    return initialState()
  },
  methods: {
    async openModal(title, link) {
      Object.assign(this.$data, initialState())
      this.open = true
      this.modalLoading = true
      this.link = link
      this.title = title
      this.modalLoading = false
    },
  },
}
</script>
