<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center d-flex flex-grow-1 d-lg">
      <div>
        <b-tooltip
          target="refresh_cache"
          triggers="hover"
          variant="primary"
        >
          Clear App Cache
        </b-tooltip>
        <div
          id="refresh_cache"
          @click="refreshCache"
        >
          <img
            class="cursor-pointer"
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0ibHVjaWRlIGx1Y2lkZS1wYWludGJydXNoIj48cGF0aCBkPSJNMTguMzcgMi42MyAxNCA3bC0xLjU5LTEuNTlhMiAyIDAgMCAwLTIuODIgMEw4IDdsOSA5IDEuNTktMS41OWEyIDIgMCAwIDAgMC0yLjgyTDE3IDEwbDQuMzctNC4zN2EyLjEyIDIuMTIgMCAxIDAtMy0zWiIvPjxwYXRoIGQ9Ik05IDhjLTIgMy00IDMuNS03IDRsOCAxMGMyLTEgNi01IDYtNyIvPjxwYXRoIGQ9Ik0xNC41IDE3LjUgNC41IDE1Ii8+PC9zdmc+"
          >
        </div>
      </div>
      <div>
        <b-tooltip
          target="support-icon"
          triggers="hover"
          variant="primary"
        >
          Customer Support
        </b-tooltip>
        <a
          id="support-icon"
          href="https://projects.curfox.com/login"
          target="_blank"
        >
          <img
            class="support-icon"
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0ibHVjaWRlIGx1Y2lkZS1oZWxwLWNpcmNsZSI+PGNpcmNsZSBjeD0iMTIiIGN5PSIxMiIgcj0iMTAiLz48cGF0aCBkPSJNOS4wOSA5YTMgMyAwIDAgMSA1LjgzIDFjMCAyLTMgMy0zIDMiLz48cGF0aCBkPSJNMTIgMTdoLjAxIi8+PC9zdmc+"
          >
        </a>
      </div>
      &nbsp;
      <div>
        <b-tooltip
          target="resource-icon"
          triggers="hover"
          variant="primary"
        >
          Resources
        </b-tooltip>
        <a
          id="resource-icon"
          href="/resources"
          target="_blank"
        >
          <img
            class="resource-icon"
            src="/globe.svg"
          >
        </a>
      </div>
    </div>
    <div
      class="bookmark-wrapper business-title align-items-center flex-grow-1 d-none d-lg-flex"
      style="z-index:-1"
    >
      <table>
        <tr>
          <b class="nav-text">{{ form.company_name }}</b>
        </tr>
      </table>
    </div>
    <br>

    <b-navbar-nav class="nav align-items-center justify-content-center ml-auto">
      <general-search />
      <dark-toggler class="d-block" />
      <locale />
      <notification-dropdown />
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user dropdown-no-scroll"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ currentUser ? currentUser.first_name : '' }}
            </p>
            <span class="user-status">{{ currentUser ? currentUser.role.name : '' }}</span>
            <span
              v-if="warehouseList.length === 1"
              class="user-status"
              style="margin-top:4px;"
            >{{ warehouseList[0].name }}</span>
            <span
              v-if="warehouseList.length > 1"
              class="user-status"
              style="margin-top:4px;"
              @click="showWarehouseModal"
            >{{ warehouseList.length }}  Warehouses</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/12-small.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          :href="apkUrl"
          target="_blank"
        >
          <feather-icon
            size="16"
            icon="SmartphoneIcon"
            class="mr-50"
          />
          <span>Download Apk</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'business-setting' }"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="onClickLogout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <warehouse-modal ref="warehouse_modal" />
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BAvatar, BTooltip,
} from 'bootstrap-vue'
import {
  mapActions,
  mapGetters,
} from 'vuex'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import useJwt from '@/auth/jwt/useJwt'
import Locale from '@/@core/layouts/components/app-navbar/components/Locale.vue'
import {
  appEnv, bucketUrl, tenant, apiUrl,
} from '@/constants/config'
import NotificationDropdown from '@/@core/layouts/components/app-navbar/components/NotificationDropdown.vue'
import GeneralSearch from '@/@core/layouts/components/app-navbar/components/GeneralSearch.vue'
import WarehouseModal from '@/components/Warehouse/WarehouseModal.vue'

const ResourceRepository = RepositoryFactory.get('resource')
const BusinessRepository = RepositoryFactory.get('businessSetting')

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
    BTooltip,
    NotificationDropdown,
    Locale,
    WarehouseModal,
    DarkToggler,
    GeneralSearch,
  },
  mixins: [ErrorMessage],
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      bucketUrl,
      tenant,
      apiUrl,
      form: {},
      warehouseList: [],
      useJwt,
      appEnv,
    }
  },
  computed: {
    apkUrl() {
      const appName = appEnv === 'production' ? tenant : `${tenant}dev`
      return `${bucketUrl}apk/app-${appName}-release.apk`
    },
    ...mapGetters(['currentUser']),
  },
  async mounted() {
    await this.fetchBusinessSettings()
    await this.fetchAssignedWarehouses()
  },
  methods: {
    ...mapActions(['logout']),
    async onClickLogout() {
      // eslint-disable-next-line no-underscore-dangle
      this.__clearCache()
      await this.logout()
    },
    async fetchAssignedWarehouses() {
      try {
        this.loading = true
        this.warehouseList = localStorage.getItem('assigned_warehouse_list') ? JSON.parse(localStorage.getItem('assigned_warehouse_list')) : (await ResourceRepository.getAssignedWarehousesNoPagination()).data.data
        if (!localStorage.getItem('assigned_warehouse_list')) localStorage.setItem('assigned_warehouse_list', JSON.stringify(this.warehouseList))
      } catch (e) {
        // this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async fetchBusinessSettings() {
      try {
        this.loading = true
        this.form = localStorage.getItem('businessSettings') ? JSON.parse(localStorage.getItem('businessSettings')) : (await BusinessRepository.getbusinessSettingsResource()).data.data
        if (!localStorage.getItem('businessSettings')) localStorage.setItem('businessSettings', JSON.stringify(this.form))
      } catch (e) {
        // this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    showWarehouseModal() {
      setTimeout(() => {
        document.body.click()
      }, 45)
      this.$refs.warehouse_modal.openModal()
    },
    refreshCache() {
      // eslint-disable-next-line no-underscore-dangle
      this.__clearCache()
      const { currentRoute } = this.$router
      if (currentRoute.name === 'Selected-main-operation' || currentRoute.name === 'Selected-warehouse-operation' || currentRoute.name === 'Selected-pickup-operation') {
        this.$router.go(-1)
      } else {
        window.location.reload()
      }
    },
    // Tracking link function moves to new page
  },
}
</script>
<style lang="css" scoped>
.support-icon{
  margin-left:5px;
}

.tracking-icon{
  margin-left: 4px;
}

.business-title {
  justify-content: flex-start;
  font-size: 20px;
  display: flex;
  align-items: center;
}
.resource-icon {
  width: 24px;
  margin-left:3px;
  height: 24px;
  margin-right:3px;
}
</style>
