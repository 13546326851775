<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >

    <div class="w-100 d-flex justify-content-between align-items-center">
      <b-col
        class="content-header-left"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col
            :md="$route.meta.pageTitle === 'Warehouse Operation' ? 10 : 9"
            class="d-flex align-items-center mb-2"
          >
            <h2 class="content-header-title float-left pr-1 mb-0">
              {{ $route.meta.pageTitle }}
            </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item to="/">
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  v-for="item in $route.meta.breadcrumb"
                  :key="item.text"
                  :active="item.active"
                  :to="item.to"
                >
                  {{ item.text }}
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  v-if="formattedName"
                  class="text-primary"
                >
                  {{ formattedName }}
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
            <span
              v-if="$route.meta.help"
            >
              <a
                :href="$route.meta.help"
                target="_blank"
              >
                <feather-icon
                  v-b-tooltip.hover.v-primary="'Click to view user guide'"
                  icon="HelpCircleIcon"
                  size="15"
                  class="cursor-pointer text-primary ml-1 ml-md-0"
                />
              </a>
            </span>
            <!-- <img src="/santa-sleigh.png" class="santa-sleigh" /> -->
          </b-col>
          <b-col
            md="3"
            class="mb-2"
          >
            <b-button
              v-if="$route.meta.pageTitle === 'Dashboard' || $route.meta.pageTitle === 'Warehouse Dashboard'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              size="sm"
              variant="outline-primary"
              class="refresh-button d-flex align-items-center justify-content-center"
              :disabled="($route.meta.pageTitle === 'Dashboard' && dashboardRefreshButtonDisabled) || ($route.meta.pageTitle === 'Warehouse Dashboard' && warehouseDashboardRefreshButtonDisabled)"
              @click="refreshDashboard()"
            >
              <feather-icon
                icon="RefreshCwIcon"
                class="mr-50 refresh-icon"
              />
              <span class="align-middle refresh-span">Refreshed</span>
              <time-ago
                class="timeAgo"
                :datetime="$route.meta.pageTitle === 'Warehouse Dashboard' ? warehouseDashboardRefreshDate : refreshedDate"
                refresh
                locale="en"
                :long="longString"
                :style="{color: 'black'}"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-button
        v-if="$route.meta.suffix_button_text"
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="primary"
        size="sm"
        class="mb-2 mr-1"
        :href="trackingLink"
        target="_blank"
      >
        {{ $route.meta.suffix_button_text }}
      </b-button>
    </div>
    <view-help-modal ref="viewHelpModal" />
  </b-row>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  VBTooltip,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { appEnv } from '@/constants/config'
import ViewHelpModal from '@/components/Help/HelpModal.vue'
import { TimeAgo } from 'vue2-timeago'
import 'vue2-timeago/dist/vue2-timeago.css'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    TimeAgo,
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BButton,
    ViewHelpModal,
  },
  data() {
    return {
      appEnv,
      longString: true,
      refreshedDate: new Date(),
      dashboardRefreshButtonDisabled: false,
      warehouseDashboardRefreshButtonDisabled: false,
    }
  },
  computed: {
    formattedName() {
      return this.$route.params.name ? this.$route.params.name.replace(/-/g, ' ') : ''
    },
    warehouseDashboardRefreshDate() {
      // Check if the Warehouse Dashboard refresh button was recently clicked
      if (this.warehouseDashboardRefreshButtonDisabled) {
        return new Date()
      }
      // If not recently clicked, retrieve the last refresh date from localStorage
      let warehouseRefreshedDate = new Date()
      const refreshDate = localStorage.getItem(`${this.$route.params.id}-warehouse-dashboard-refresh-time`)
      if (refreshDate) {
        warehouseRefreshedDate = JSON.parse(refreshDate)
      }
      return warehouseRefreshedDate
    },
  },
  mounted() {
    this.getRefreshDate()
  },
  methods: {
    onClickHelp(title, link) {
      this.$refs.viewHelpModal.openModal(title, link)
    },
    refreshDashboard() {
    // Determine which refresh button to disable based on the current page title
      if (this.$route.meta.pageTitle === 'Dashboard') {
        if (this.dashboardRefreshButtonDisabled) {
          return
        }
        // Disable the Dashboard refresh button
        this.dashboardRefreshButtonDisabled = true
        setTimeout(() => {
          this.dashboardRefreshButtonDisabled = false
        }, 60000)
        localStorage.removeItem('main-dashboard')
        localStorage.removeItem('dashboard-refresh-time')
        this.$root.$emit('refreshDashboardData')
        this.refreshedDate = new Date()
      } else if (this.$route.meta.pageTitle === 'Warehouse Dashboard') {
        if (this.warehouseDashboardRefreshButtonDisabled) {
          return
        }
        // Disable the Warehouse Dashboard refresh button
        this.warehouseDashboardRefreshButtonDisabled = true
        setTimeout(() => {
          this.warehouseDashboardRefreshButtonDisabled = false
        }, 60000)
        localStorage.removeItem(`${this.$route.params.id}-warehouse-dashboard`)
        localStorage.removeItem(`${this.$route.params.id}-warehouse-dashboard-refresh-time`)
        this.$root.$emit('refreshWarehouseDashboardData')
      }
    },
    getRefreshDate() {
      const refreshDate = localStorage.getItem('dashboard-refresh-time')
      if (refreshDate) {
        this.refreshedDate = JSON.parse(refreshDate)
      }
    },

  },
}
</script>

<style scoped>
  .breadcrumb-item a {
  text-decoration: none;
  color: inherit;
  cursor: default;
}
.santa-sleigh {
  width: 100px;
  margin-left: 20px;
  animation: animate 5s ease-in-out infinite;
}
@keyframes animate {
  0%,100% {
    transform: translateY(-15px);
  }
  50% {
    transform: translateY(15px);
  }
}
.refresh-button {
  float: right;
}

.refresh-icon {
  color: black !important;
}
.refresh-span {
  color: black !important;
  font-size: 13px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
margin-bottom: 1px;
}
.timeAgo {
  margin-left: 5px;
  font-size: 13px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
</style>
