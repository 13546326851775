<template>
  <div>
    <b-nav-item-dropdown
      class="dropdown-no-scroll mr-25"
      menu-class="dropdown-menu-media"
      right
      @show="onOpenDropdown"
    >
      <template #button-content>
        <feather-icon
          badge-classes="bg-danger"
          class="search-icon"
          icon="SearchIcon"
          size="21"
        />
      </template>

      <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
          <h4 class="py-1">
            GENERAL SEARCH
          </h4>
        </div>
      </li>

      <li>
        <b-card>
          <b-tabs
            class="custom-tabs"
            pills
            nav-class="justify-content-center mt-2"
          >
            <!-- Waybill Tab -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon="FileMinusIcon"
                  class="my-1 feather-icon"
                  size="20"
                />
                <span class="tab-name">Waybill</span>
              </template>

              <b-card-text>
                <div class="dropdown-header mt-2">
                  <ValidationObserver
                    ref="searchWaybill"
                    v-slot="{ handleSubmit }"
                    slim
                  >
                    <b-form
                      @submit.prevent="handleSubmit(onClickSearchWayBill)"
                    >
                      <b-form-group
                        class="required"
                        label="Waybill Number"
                        label-for="h-waybill_number"
                        label-class="form-label-large"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="waybill_number"
                          rules="required"
                        >
                          <b-form-input
                            id="waybill_number"
                            v-model="waybillNumber"
                            :state="errors.length ? false : null"
                            placeholder="Enter Waybill Number"
                            autocomplete="off"
                            class="input-spacing"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </ValidationProvider>
                      </b-form-group>

                      <div class="float-right button-group">
                        <b-button
                          type="submit"
                          class="mr-1 custom-button-color"
                        >
                          Search
                        </b-button>
                        <b-button
                          type="reset"
                          variant="outline-secondary"
                          @click="clearFormAndValidation()"
                        >
                          Clear
                        </b-button>
                      </div>
                    </b-form>
                  </ValidationObserver>

                  <div
                    v-if="!orderId && !disabled"
                    class="dropdown-header text-center"
                  >
                    <p>No Results Found.</p>
                  </div>
                </div>
              </b-card-text>
            </b-tab>

            <!-- Demarcation Tab -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon="GitMergeIcon"
                  size="20"
                  class="my-1 feather-icon"
                />
                <span class="tab-name">Demarcation</span>
              </template>

              <b-card-text>
                <div class="dropdown-header mt-2">
                  <ValidationObserver
                    ref="searchDemarcation"
                    v-slot="{ handleSubmit }"
                    slim
                  >
                    <b-form @submit.prevent="handleSubmit(onClickSearchDemarcation)">
                      <b-form-group
                        class="required"
                        label="Demarcation"
                        label-for="h-demarcation"
                        label-class="form-label-large"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="demarcation"
                          rules="required"
                        >
                          <v-select
                            v-model="selectedDemarcation"
                            :options="demarcationOptions"
                            :reduce="option => option.name"
                            label="name"
                            placeholder="Select Demarcation"
                            class="input-spacing"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </ValidationProvider>
                      </b-form-group>

                      <b-form-group
                        v-if="selectedDemarcation"
                        class="required"
                        :label="demarcationLabel"
                        label-for="h-cName"
                        label-class="form-label-large"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="input_name"
                          rules="required"
                        >
                          <b-form-input
                            id="name"
                            v-model="inputName"
                            placeholder="Enter Name"
                            autocomplete="off"
                            class="input-spacing"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </ValidationProvider>
                      </b-form-group>

                      <div :class="['float-right', selectedDemarcation ? 'mt-1' : 'button-group']">
                        <b-button
                          type="submit"
                          class="mr-1 custom-button-color"
                        >
                          Search
                        </b-button>
                        <b-button
                          type="reset"
                          variant="outline-secondary"
                        >
                          Clear
                        </b-button>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </div>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </li>
    </b-nav-item-dropdown>

    <view-city-result-modal ref="viewCityResultModal" />
    <view-order-modal ref="viewOrderModal" />
  </div>
</template>

<script>
import {
  BNavItemDropdown,
  BTabs,
  BTab,
  BCardText,
  BButton,
  BFormGroup,
  BForm,
  BFormInput,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ViewOrderModal from '@/components/AllOrders/ViewOrderModal.vue'
import viewCityResultModal from '@/components/GeneralSearch/viewCityResultModal.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const genarelSearchRepository = RepositoryFactory.get('genarelSearch')

export default {
  components: {
    BNavItemDropdown,
    BTabs,
    BTab,
    BCardText,
    BButton,
    BFormGroup,
    BForm,
    BFormInput,
    vSelect,
    ViewOrderModal,
    viewCityResultModal,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      selectedDemarcation: '',
      demarcationOptions: [{ key: 1, name: 'City' }],
      waybillNumber: '',
      disabled: true,
      orderId: '',
      inputName: '',
    }
  },
  computed: {
    demarcationLabel() {
      return this.selectedDemarcation || 'Demarcation'
    },
  },
  methods: {
    async onClickSearchWayBill() {
      try {
        const { data } = (await genarelSearchRepository.getOrdersByWarehouse(this.waybillNumber)).data
        this.orderId = data.id
        this.onClickViewOrder()
      } catch (e) {
        this.disabled = false
        this.convertAndNotifyError(e)
      }
    },
    onClickViewOrder() {
      this.$refs.viewOrderModal.openModal(this.orderId)
    },
    async onClickSearchDemarcation() {
      this.$refs.viewCityResultModal.openModal(this.inputName)
    },
    onOpenDropdown() {
      this.clearFormAndValidation()
    },
    clearFormAndValidation() {
      this.waybillNumber = ''
      this.selectedDemarcation = ''
      this.inputName = ''
      this.disabled = true
      this.orderId = ''
      this.$refs.searchWaybill.reset()
      this.$refs.searchDemarcation.reset()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/general-search.scss';
</style>
