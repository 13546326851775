<template>
  <b-modal
    id="view-city-result-modal"
    ref="modal"
    v-model="open"
    size="ov-custom"
    no-close-on-backdrop
    hide-footer
    centered
    title="General Search Result"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <b-table
        :items="updatedRows"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        hover
        responsive
        striped
        show-empty
        class="hide-vertical-scroll"
      >
        <template #empty>
          <TableDataFetching
            :rows="updatedRows"
            :data-loading="dataLoading"
          />
        </template>
        <template #cell(demarcationType)="data">
          <div class="font-weight-bold">
            {{ data.value || 'N/A' }}
          </div>
        </template>
        <template #cell(name)="data">
          {{ data.value || 'N/A' }}
        </template>
        <template #cell(default_warehouse.name)="data">
          <b-badge variant="light-danger">
            {{ data.value || 'N/A' }}
          </b-badge>
        </template>
        <template #cell(zone.name)="data">
          {{ data.value || 'N/A' }}
        </template>
        <template #cell(state.name)="data">
          {{ data.value || 'N/A' }}
        </template>
      </b-table>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BModal,
  BOverlay,
  VBModal,
  BTable,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const genarelSearchRepository = RepositoryFactory.get('genarelSearch')

function initialState() {
  return {
    open: false,
    modalLoading: false,
    dataLoading: false,
    sortBy: '',
    sortDesc: false,
    sortDirection: 'asc',
    fields: [
      { key: 'demarcationType', label: 'Demarcation Type', sortable: true },
      { key: 'name', label: 'City', sortable: false },
      { key: 'default_warehouse.name', label: 'Warehouse', sortable: false },
      { key: 'zone.name', label: 'Zone', sortable: false },
      { key: 'state.name', label: 'State', sortable: true },
    ],
    rows: [],
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BModal,
    BOverlay,
    BTable,
    BBadge,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  computed: {
    updatedRows() {
      return this.rows.map(row => ({
        ...row,
        demarcationType: 'City',
      }))
    },
  },
  methods: {
    async openModal(inputName) {
      Object.assign(this.$data, initialState())
      this.open = true
      this.inputName = inputName
      await this.fetchSearchResult()
    },
    async fetchSearchResult() {
      this.dataLoading = true
      try {
        const { data } = (await genarelSearchRepository.cityListByName(this.inputName)).data
        this.rows = data
      } catch (e) {
        // this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
  },
}
</script>
  <style scoped>
  </style>
