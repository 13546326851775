<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded header officeToggler">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul
          class="nav navbar-nav flex-row"
          :class="{ 'nav-header': !isVerticalMenuCollapsed || isMouseHovered }"
        >
          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link
              class="navbar-brand"
              to="/"
            >
              <h2
                class="brand-text"
                style="font-size: 25px;margin-left: 0;"
              >
                {{ appName }}
              </h2>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="25"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="25"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>

        <!-- Logo -->
        <ul
          class="nav navbar-nav flex-row mt-1"
          :style="{ justifyContent: !isVerticalMenuCollapsed || isMouseHovered ? 'center' : 'left', height: !isVerticalMenuCollapsed || isMouseHovered ? '80px' : '52px' }"
        >
          <li class="nav-item justify-content-center d-flex">
            <div
              v-if="loading"
              class="spinner-border"
              role="status"
            >
              <span class="sr-only">Loading...</span>

            </div>
            <b-img
              v-if="!loading"
              :src="courier.company_logo ? `${bucketUrl}${courier.company_logo}` : defaultCompanyLogo"
              alt="logo"
              :style="{ width: !isVerticalMenuCollapsed || isMouseHovered ? '80px' : '52px' }"
            />
          </li>
        </ul>
      </slot>
    </div>

    <!-- / main menu header-->

    <!-- Green Button - Positioning -->
    <div
      v-show="tenant === demoTenant"
      class="green-button"
    >
      <b-button
        class="green-button-container"
        variant="gradient-success"
        @click="onClickTrail"
      >
        Start 14 Days Free Trial
      </b-button>
    </div>

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg, BButton } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
// eslint-disable-next-line no-unused-vars
import {
  bucketUrl,
  defaultCompanyLogo,
  appEnv,
  // eslint-disable-next-line no-unused-vars
  tenant,
  demoTenant,
} from '@/constants/config'
import { $themeConfig } from '@themeConfig'
import ErrorMessage from '@core/mixins/ErrorMessage'
import navMenuItems from '@/navigation/vertical'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

const BusinessRepository = RepositoryFactory.get('businessSetting')

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
    BButton,
  },
  mixins: [ErrorMessage],
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  mounted() {
    this.fetchBusinessSettings()
  },
  methods: {
    async fetchBusinessSettings() {
      try {
        this.loading = true
        this.courier = localStorage.getItem('businessSettings') ? JSON.parse(localStorage.getItem('businessSettings')) : (await BusinessRepository.getbusinessSettingsResource()).data.data
        if (!localStorage.getItem('businessSettings')) localStorage.setItem('businessSettings', JSON.stringify(this.courier))
      } catch (e) {
        // this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    onClickTrail() {
      if (appEnv === 'staging') {
        window.location.href = 'https://self-registration.curfox.parallaxtec.com/register'
      } else if (appEnv === 'production') {
        window.location.href = 'https://welcome.curfox.com/register'
      }
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName } = $themeConfig.app

    return {
      loading: true,
      courier: {},
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      bucketUrl,
      defaultCompanyLogo,

      // tenants
      demoTenant,
      tenant,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
.green-button .green-button-container {
  margin-left: 14px; /* Adjust the margin value as per your requirement */
  width: 232px;
  height: 40px;
  color: rgb(255, 249, 249)!important;
  font-size: 16px;
  font-family: 'FeatherFont', Arial, sans-serif;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-top:15px;
}
.header {
  margin-bottom: 100px;
}
.officeToggler {
  margin-top: 4px;
}
</style>
